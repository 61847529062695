<template>
    <div class="residentdatabase-preview-form" v-show="display">
        <div class="close-btn liefeng-icon liefeng-icon-close" @click="onBack"></div>
        <div class="form-page">
            <CompForm :forms="forms" :config="formConfig" @on-submit="onSubmit"></CompForm>
        </div>
    </div>
</template>

<script>
import CompForm from "../../customform/components/CompForm.vue"

export default {
    components: { CompForm },

    data() {
        return {
            display: false,
            forms: null,

            formConfig: {
                switch: {
                    open: "1",
                    close: "2",
                },
            },
        }
    },

    methods: {
        onDisplay(selected) {
            if (!selected || selected.length <= 0) return

            const forms = []

            for (let i = 0; i < selected.length; i++) {
                const v = selected[i]

                // 忽略详细地址组件，合并在 ElemAddr 组件
                if (v.fieldType === "hidden") {
                    continue
                }

                if (v.options) {
                    v.options.forEach(o => {
                        o.name = o.label
                    })
                }

                v.max = v.limitMaxLen || (v.fieldType === "upload" ? (v.validate?.arrayType ? 9 : null) : null)

                const type = this.getFormType(v)

                if (type === "upload" && v.fieldType === "uploadFile") {
                    v.resource_type = "file"
                }

                forms.push({
                    ...v,
                    serial: v.sortIndex,
                    type: type,
                    name: v.fieldId,
                    title: v.fieldName,
                    verify: v.validate?.inputValueType,
                    multiple: v.validate?.arrayType,
                })
            }

            this.forms = forms

            this.$nextTick(() => {
                this.display = true
            })
        },

        /**
         * 获取表单项类型
         */
        getFormType(v) {
            if (v.validate?.inputValueType === "mobile") {
                return "mobile"
            }

            if (v.fieldType === "select" && v.childFormOperates?.length > 0) {
                return "radio"
            }

            if (v.fieldType === "uploadFile") {
                return "upload"
            }

            if (this.type !== "DYTX") {
                return { text: "input" }[v.fieldType] || v.fieldType
            }

            return v.fieldType
        },

        onBack() {
            this.display = false
        },

        onSubmit(evt) {
            this.$Message.info("当前表单仅限预览")
        },
    },
}
</script>

<style lang="less">
.residentdatabase-preview-form {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 120;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;

    .close-btn {
        position: absolute;
        top: 20px;
        right: 30px;
        font-size: 40px;
        color: #fff;
        font-weight: bold;
        cursor: pointer;
    }

    .form-page {
        width: 350px;
        height: 90%;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        overflow: hidden;
    }
}
</style>
